.filter-group {
  position: relative;

  .filter-group__control {
    display: flex;
    margin: 5px;
    padding: 0 10px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;

    p {
      margin: 0 0 0 5px;
      font-weight: bold;
      user-select: none;
    }

    .ox-icon {
      line-height: 45px;
      color: var(--ox-color-primary--light-20);
    }
  }

  .filter-dropdown {
    position: absolute;
    padding: 0;
    top: 100%;
    width: 100%;

    list-style: none;

    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);

    li {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      line-height: 40px;
      user-select: none;
      cursor: pointer;

      .label {
        display: flex;
      }

      &:hover, &.checked {
        font-weight: bold;
      }

      .ox-icon {
        line-height: 40px;
        color: var(--ox-color-primary--light-20);
        font-size: 22px;
      }
    }
  }
}

$filter-group-height: 50px;
$filter-group-width: 32%;

.filters-panel {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  margin-top: 10px;
  z-index: 1;
  width: 100%;
  padding: 20px;

  background-color: white;
  box-shadow: 0 0.5rem 2rem 0 rgba(121,136,137,.26);

  &.hidden {
    display: none;
  }

  .filter-group {
    width: $filter-group-width;

    p {
      line-height: $filter-group-height;
    }
  }
}
